import { createTheme } from "@mui/material/styles";

import darkScrollbar from "./scroll";

const dark = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#f15a24",
            light: "#f37b4f",
            dark: "#a83e19",
            contrastText: "#ffffff",
            overlay: "#fb718587",
        },
        secondary: {
            main: "#2F4858",
            light: "#586c79",
            dark: "#20323d",
            contrastText: "#fff",
        },
        disabled: {
            dark: "#9a9a9a",
            main: "#c3c3c3",
            light: "#e3e3e3",
            contrastText: "#fff",
        },
        error: {
            main: "#f44336",
            light: "#f6685e",
            dark: "#aa2e25",
            contrastText: "#ffffff",
        },
        warning: {
            main: "#ff9800",
            light: "#ffac33",
            dark: "#b26a00",
            contrastText: "rgba(0,0,0,0.87)",
        },
        info: {
            main: "#2196f3",
            light: "#4dabf5",
            dark: "#1769aa",
            contrastText: "#ffffff",
        },
        success: {
            main: "#4caf50",
            light: "#6fbf73",
            dark: "#357a38",
            contrastText: "rgba(0,0,0,0.87)",
        },
        divider: "rgba(255,255,255,0.7)",
        background: {
            default: "#1f1d1c",
            paper: "#2c2b2b",
            gridcell: "#151515",
            defaultNews: "#080808 linear-gradient(16deg, #6f6c6c, transparent)",
            transparentTab: "rgba(241, 90, 36,0.5)",
        },
        text: {
            main: "#fff",
            primary: "#ffffff",
            secondary: "rgba(255,255,255,0.7)",
            disabled: "rgba(255,255,255,0.5)",
            hint: "rgba(255,255,255,0.5)",
        },
        side: {
            main: "rgba(255, 255, 255, 0.7)",
        },
        message: {
            background: "#3a3a3a",
            paper: "#1b1a1a",
        },
        contours: {
            primary: "#F96E2A",
        },
        line: {
            lineColor: "#232222",
        },
    },
    typography: {
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        htmlFontSize: 16,
        h1: {
            fontSize: "6rem",
            fontWeight: "bold",
            lineHeight: 1.16,
            letterSpacing: "-0.01em",
            fontFamily: "Poppins, sans-serif",
        },
        h2: {
            fontSize: "3.7rem",
            fontWeight: "bold",
            lineHeight: 1.2,
            letterSpacing: "0.01em",
            fontFamily: "Poppins, sans-serif",
        },
        h3: {
            fontSize: "3rem",
            fontWeight: "bold",
            lineHeight: 1.16,
            letterSpacing: "0em",
            fontFamily: "Poppins, sans-serif",
        },
        h4: {
            fontSize: /*  "2.1rem"  */ "calc(1.8rem + 20 * ((100vw - 320px)/1680))",
            fontWeight: "bold",
            lineHeight: 1.23,
            letterSpacing: "0.02em",
            fontFamily: "Roboto, sans-serif",
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: 400,
            lineHeight: 1.33,
            letterSpacing: "0em",
            fontFamily: "Roboto, sans-serif",
        },
        h6: {
            fontSize: "1.3rem",
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: "0.02em",
            fontFamily: "Roboto, sans-serif",
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.75,
            letterSpacing: "0.01em",
            fontFamily: "Roboto, sans-serif",
        },
        subtitle2: {
            fontSize: "0.8rem",
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: "0.01em",
            fontFamily: "Roboto, sans-serif",
        },
        body1: {
            fontSize: "calc(1rem * 24 / 16))",
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: "0.01em",
            fontFamily: "Roboto, sans-serif",
        },
        body2: {
            fontSize: "0.8rem",
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: "0.01em",
            fontFamily: "Roboto, sans-serif",
        },
        button: {
            textTransform: "capitalize",
            fontSize: "0.8rem",
            fontWeight: "bold",
            lineHeight: 1.75,
            letterSpacing: "0.02em",
            fontFamily: "Roboto, sans-serif",
        },
        caption: {
            fontSize: "0.8rem",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: "0.03em",
            fontFamily: "Poppins, sans-serif",
        },
        overline: {
            fontSize: "0.8rem",
            fontWeight: 400,
            lineHeight: 2.66,
            letterSpacing: "0.08em",
            fontFamily: "Roboto, sans-serif",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: darkScrollbar({
                    track: "#FFFFFF00",
                    thumb: "#405163",
                    active: "#405163",
                }),
            },
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        background: "#f3f3f3",
                        color: "#dadada",
                    },
                },
            },
        },
    },
    chat: {
        background: {
            paper: "#212121",
            paper2: "#444444",
            conversationCloud: "#a83e19",
            conversationCloud2: "#2c2b2b",
        },
    },
    noticeCard: {
        text: "#FFE0B2",
    },
});

export default dark;
