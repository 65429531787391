/* eslint-disable no-unused-vars */
import { useRef } from "react";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import PropTypes from "prop-types";

// @mui/material
import { Box, Typography } from "@mui/material";

//  components
import HeroCard from "../HeroCard/HeroCard";

// images

import ReactPlayer from "react-player";

const VideoNewsCard = (props) => {
    const { item = [], showHeader, category, sx, videoSize = "60%" } = props;
    const videoRef = useRef(null);

    function enterFullScreen() {
        if (screenfull.isFullscreen) return;
        else screenfull.request(findDOMNode(videoRef.current));
    }
    function exitFullscreen() {
        if (videoRef.current) {
            videoRef.current.showPreview();
        }
        if (!screenfull.isFullscreen) return;
        else {
            screenfull.exit();
        }
    }

    return (
        <Box
            sx={{
                height: { xs: "236px", sm: "266px", lg: "266px", xl: "250px" },
                borderRadius: "5px",
                position: "relative",
                overflow: "hidden",
                background:
                    "linear-gradient(0deg, rgba(34,34,34,1) 0%, rgba(34,34,34,0.8194852941176471) 75%, rgba(0,212,255,0) 100%)",
                ...sx,
            }}
        >
            {showHeader && (
                <Box
                    sx={{
                        background: "linear-gradient(to right,#ff6400 28%,#ff3936 100%);",
                        borderRadius: "5px 5px 0 0",
                        opacity: "0.7",
                        width: "100%",
                        height: "12%",
                        zIndex: "1",
                        pl: "5px",
                    }}
                >
                    <HeroCard category={category} />
                </Box>
            )}

            <ReactPlayer
                width="100%"
                height={videoSize}
                url={
                    item?.attributes?.media || item?.url || item?.attributes?.url || item?.Link_youtube
                }
                playing={false}
                muted={false}
                controls={true}
                light
                ref={videoRef}
                onEnded={exitFullscreen}
                onPlay={enterFullScreen}
                config={{
                    youtube: {
                        playerVars: {
                            origin: "https://www.youtube.com",
                            widget_referrer: window.location.href,
                        },
                    },
                }}
            />

            <Box
                sx={{
                    width: "100%",
                    pl: "20px",
                    position: "relative",
                    color: "#FFF",
                }}
            >
                <div
                    color="#FFF"
                    dangerouslySetInnerHTML={{
                        __html:
                            item?.attributes?.author ||
                            item?.attributes?.title ||
                            item?.name ||
                            item?.title ||
                            item?.Title,
                    }}
                ></div>

                {/*item?.attributes?.author || item?.attributes?.title ? (
                    <Typography
                        title={
                            item?.attributes?.author ||
                            item?.attributes?.title ||
                            item?.name || item?.title
                        }
                        variant="subtitle1"
                        fontWeight="bold"
                        color="#FFF"
                    >
                        {item.attributes?.author
                            ? item.attributes?.author?.length < 31
                                ? item?.attributes?.author
                                : `${item?.attributes?.author?.slice(0, 28)}...`
                            : item.attributes?.title.length < 31
                              ? item?.attributes?.title
                              : `${item?.attributes?.title?.slice(0, 30)}...`}
                    </Typography>
                ) : (
                    <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
                        {item?.name || item?.title.length < 31
                            ? item?.title
                            : `${item?.title?.slice(0, 30)}...`}
                    </Typography>
                )*/}

                {/*item.attributes?.content || item.attributes?.description || item?.description ? (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            height: "auto",
                            fontSize: { xs: "14px", sm: "1rem", xl: "15px" },
                        }}
                        variant="body1"
                        color="#FFF"
                    >
                        {item?.attributes?.content ||
                            item?.attributes?.description ||
                            item?.description}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            height: "auto",
                            fontSize: { xs: "14px", sm: "1rem", xl: "15px" },
                        }}
                        variant="body1"
                        color="#FFF"
                    >
                        {item.channel}
                    </Typography>
                )*/}
            </Box>
        </Box>
    );
};

VideoNewsCard.propTypes = {
    item: PropTypes.shape({
        photo: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    index: PropTypes.number,
};

export default VideoNewsCard;
